import React, { useState, useEffect, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useMount, useMedia } from 'react-use'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import VisibilitySensor from 'react-visibility-sensor'
var Scroll = require('react-scroll')
var ScrollElement = Scroll.Element
var scroller = Scroll.scroller

import { Layout, Images, Ticker } from '../components'
import { container, padding, bgImage } from '../styles/global'
import { media, breakpoints } from '../styles/utils'
import { parseACF } from '../utils'

import { HeaderContext } from '../components/Header/HeaderContext'

import { data } from '../data/home'

const Index = (props) => {
  const [hovering, setHovering] = useState(false)
  const headerContext = useContext(HeaderContext)
  const isMobile = useMedia(`(max-width: ${breakpoints['phone']}px)`)

  console.log(headerContext.isVisible)

  useEffect(() => {
    if (headerContext.isVisible) {
      setHovering(false)
    }
  })

  const handleClick = (data) => {
    if (isMobile) {
      setHovering(data)

      if (window.scrollY >= 100) {
        setHovering(false)
      }
    }
  }

  function visibilityOnChange(isVisible) {
    headerContext.setIsVisible(!isVisible)
  }

  const renderHeroLinks = () => {
    const items = data.heroLinks.map((item, i) => {
      return (
        <Item
          key={i}
          onClick={() => handleClick(item.color)}
          onMouseEnter={() => !isMobile && setHovering(item.color)}
          onMouseLeave={() => !isMobile && setHovering(false)}
          className={
            item.slug === 'landscapes' &&
            hovering === 'landscapes' &&
            'multiply-text'
          }
          dangerouslySetInnerHTML={{
            __html: item.title,
          }}
        />
      )
    })

    return <HeroLinks>{items}</HeroLinks>
  }

  const renderHeroImages = () => {
    const items = data.heroLinks.map((item, i) => {
      if (hovering === item.slug) {
        return (
          <Images
            key={i}
            layout={item.layout}
            setHovering={setHovering}
            hovering={hovering === item.color}
            {...item}
          />
        )
      } else {
        return
      }
    })

    return <HeroImages>{items}</HeroImages>
  }

  const scrollToContent = () => {
    scroller.scrollTo('content', {
      smooth: true,
    })
  }

  const renderHeroMobileHeader = () => {
    return (
      <HeroHeader>
        <Container>
          <InstagramLink
            href="https://www.instagram.com/formlandscaping/?hl=en"
            target="_blank"
          >
            Instagram
          </InstagramLink>
        </Container>
      </HeroHeader>
    )
  }

  const renderHeroFooter = () => {
    return (
      <HeroFooter>
        <Container>
          <ScrollLink onClick={scrollToContent}>Information</ScrollLink>
          <InstagramLink
            href="https://www.instagram.com/formlandscaping/?hl=en"
            target="_blank"
          >
            Instagram
          </InstagramLink>
        </Container>
      </HeroFooter>
    )
  }

  const renderAbout = () => {
    const [visible, setVisible] = useState(false)

    return (
      <About>
        {!isMobile && (
          <Description
            dangerouslySetInnerHTML={{
              __html: data.about,
            }}
          />
        )}

        {isMobile && (
          <Accordion>
            <Description
              dangerouslySetInnerHTML={{
                __html: data.about_mobile,
              }}
            />

            {!visible && (
              <Subheading onClick={() => setVisible(true)}>MORE</Subheading>
            )}

            {visible && (
              <AccordionHidden>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: data.about_mobile_expanded,
                  }}
                />
              </AccordionHidden>
            )}
          </Accordion>
        )}
      </About>
    )
  }

  const renderContact = () => {
    const items = data.contact.map((item, i) => {
      return (
        <Item
          key={i}
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      )
    })
    return <Contact>{items}</Contact>
  }

  return (
    <Layout>
      <Wrapper background={hovering}>
        <VisibilitySensor
          onChange={visibilityOnChange}
          partialVisibility={true}
          offset={{ top: 50 }}
        >
          <Hero>
            {renderHeroMobileHeader()}
            {renderHeroLinks()}
            {renderHeroImages()}
            {renderHeroFooter()}
          </Hero>
        </VisibilitySensor>

        <Content as={ScrollElement} name="content">
          <Container>
            {renderAbout()}
            {renderContact()}

            <InstagramLink
              href="https://www.instagram.com/formlandscaping/?hl=en"
              target="_blank"
            >
              <Ticker content={data.marqueeText} />
            </InstagramLink>
          </Container>
        </Content>
      </Wrapper>
    </Layout>
  )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
  overflow: hidden;
`

const BGImage = styled.div`
  background-image: url(${(props) => props.image});
  ${bgImage};
  transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
  ${container}
  ${padding}
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.background};
  transition: background-color 0.3s;
  height: 100%;
`

// Hero

const Hero = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  ${media.phone`
    height: 85vh;
    // min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  `}
`

const HeroLinks = styled.div`
  position: relative;
  ${container}
  ${padding}
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${Item} {
    br {
      display: none;
    }

    ${media.phone`
			text-align: center;

			br {
				display: block;
			}

			&:not(:first-child) {
				margin-top: 32px;
			}
		`}

    &, * {
      font-weight: 500;
      font-size: 61px;
      line-height: 1.32;
      letter-spacing: 0.025em;

      ${media.phone`
				font-size: 32px;
			`}
    }

    &.multiply-text {
      z-index: 1;
      color: green;
      mix-blend-mode: difference;
    }
  }
`

// Hero Images
// see components/images

const HeroImages = styled.div`
  pointer-events: none;
`

// Hero Footer

const ScrollLink = styled.div``
const InstagramLink = styled.a``

const HeroFooter = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid black;

  &:hover {
    background-color: #fff;
    // background-color: #f4f8f5;

    ${media.phone`
			background-color: white;
		`}
  }

  ${Container} {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    max-width: none;
  }

  ${ScrollLink}, ${InstagramLink} {
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 500;

    ${media.phone`
			font-size: 12px;
		`}
  }

  ${ScrollLink} {
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    text-align: center;
  }

  ${InstagramLink} {
    z-index: 1;

    ${media.phone`
			display: none;
		`}
  }
`

// Hero Header (mobile only)

const HeroHeader = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;

  display: none;

  ${media.phone`
		display: block;
	`}

  ${Container} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  ${InstagramLink} {
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 500;

    ${media.phone`
			font-size: 12px;
		`}
  }
`

// Content

const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  //   background: #f4f8f5;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.phone`
    min-height: 85vh;
    // height: auto;
  `}

  ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.phone`
			height: initial;
			padding-top: 100px;
			padding-bottom: 100px;
		`}
  }
`

// About

const About = styled.div`
  width: 1180px;
  max-width: 100%;
  text-align: center;

  &,
  * {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.18;

    ${media.phone`
			font-size: 17px;
		`}
  }
`

const Accordion = styled.div`
  ${Subheading} {
    margin-top: 20px;
  }
`

const AccordionHidden = styled.div``

// Contact

const Contact = styled.div`
  display: flex;
  width: 1180px;
  max-width: 100%;
  margin-top: 120px;
  text-align: center;

  ${media.phone`
		flex-direction: column;	
		margin-top: 0;
	`}

  ${Item} {
    width: 33.33%;

    ${media.phone`
			width: 100%;
			margin-top: 20px;
		`}

    &, * {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.18;

      ${media.phone`
				font-size: 17px;
			`}
    }
  }
`

export default Index
