import { stripIndent } from 'common-tags'

export const data = {
  heroLinks: [
    {
      title: 'LANDSCAPES <br /> by FORM®',
      slug: 'landscapes',
      layout: 'stacked',
      multiply: true,
      images: [require('../assets/images/landscapes.jpg')],
      color: `#8f4d20`,
    },
    {
      title: 'POOLS <br /> by FORM®',
      slug: 'pools',
      layout: 'stacked',
      images: [
        require('../assets/images/pools-1.jpg'),
        require('../assets/images/pools-2.jpg'),
        require('../assets/images/pools-3.jpg'),
      ],
      color: `#004aca`,
    },
    {
      title: 'GARDENS <br /> by FORM®',
      slug: 'gardens',
      layout: 'three-across',
      images: [
        require('../assets/images/gardens-repeated.png'),
        require('../assets/images/gardens-repeated.png'),
        require('../assets/images/gardens-repeated.png'),
      ],
      color: `#46461e`,
    },
  ],
  about: stripIndent`
        FORM® collaborates with leading landscape designers to create outdoor environments as distinctive and detailed as any architectural interior space. Landscapes by FORM® is led by a design sensibility that considers personal experiences in natural settings to deliver unfailingly elegant external spaces. Irrespective of scale and character, whether hidden behind estate walls or on show to the neighbourhood, every project pursues effortless enjoyment as a vital objective. Pools by FORM® capture the indescribable magic of time spent in and around water. Gardens by FORM® provides expert horticultural care, advice and practical services to nurture creations for thriving longevity.
    `,
  about_mobile: stripIndent`
        FORM® collaborates with leading landscape designers to create outdoor environments as distinctive and detailed as any architectural interior space.
    `,
  about_mobile_expanded: stripIndent`
        Landscapes by FORM® is led by a design sensibility that considers personal experiences in natural settings to deliver unfailingly elegant external spaces. Irrespective of scale and character, whether hidden behind estate walls or on show to the neighbourhood, every project pursues effortless enjoyment as a vital objective. Pools by FORM® capture the indescribable magic of time spent in and around water. Gardens by FORM® provides expert horticultural care, advice and practical services to nurture creations for thriving longevity.
    `,
  contact: [
    stripIndent`
            Landscapes by FORM®<br>
            <a 
                href='mailto:landscapes@formlandscaping.com.au' 
                target='_blank'
            >
                landscapes@formlandscaping.com.au
            </a><br>
            Mobile No. 
            <a 
                href='tel:0422 864 887'
            >
                0422 864 887
            </a><br>
        `,
    stripIndent`
            Pools by FORM®<br>
            <a 
                href='mailto:pools@formlandscaping.com.au' 
                target='_blank'
            >
                pools@formlandscaping.com.au
            </a><br>
            Mobile No. 
            <a 
                href='tel:0422 864 887'
            >
                0422 864 887
            </a><br>
        `,
    stripIndent`
            Gardens by FORM®<br>
            <a 
                href='mailto:gardens@formlandscaping.com.au' 
                target='_blank'
            >
                gardens@formlandscaping.com.au
            </a><br>
            Mobile No. 
            <a 
                href='tel:0423 216 552'
            >
                0423 216 552
            </a><br>
        `,
  ],
  marqueeText: 'SHAPED by FORM®',
}
